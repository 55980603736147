import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
    state: {
        searchData: {},
    },
    getters: {
        getSearchData: (state) => {
            return state.searchData
        }
    },
    mutations: {
        addSearchData(state, searchData) {
            state.searchData = searchData
        }
    },
    actions: {
        addSearchData({commit}, searchData) {
            commit('addSearchData', searchData)
        }
    }
}
