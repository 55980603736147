<template>
  <v-app>
    <router-view name="NavBar"></router-view>

    <router-view name="StudioNavBar"></router-view>

    <v-content
      style="background: radial-gradient(rgb(230, 230, 230), transparent)"
      :class="{
        'content-bg': ![
          'SignIn',
          'SignUp',
          'Dashboard',
          'Video',
          'Detail',
          'Welcome',
        ].includes(this.$route.name)
          ? true
          : false,
      }"
    >
      <router-view></router-view>
    </v-content>

    <router-view name="Footer"></router-view>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
.v-application {
  font-family: "Poppins";
}
.content-bg {
  background-color: white;
}

.card {
  background: white !important;
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
  background: white !important;
}
</style>
