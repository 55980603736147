import Vue from "vue";
// import cors from 'cors'
import VueRouter from "vue-router";
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
// import StudioNavBar from "@/components/StudioNavBar.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Home",
    components: {
      NavBar,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      default: () =>
        import(/* webpackChunkName: "about" */ "../views/Home.vue"),
      Footer,
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/forgetpassword",
    name: "Forget",
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    component: () =>
      import(/* webpackChunkName: "signin" */ "../views/Auth/Forget.vue"),
    meta: { requiresVisitor: true },
  },

  {
    path: "/signin",
    name: "signIn",
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    component: () =>
      import(/* webpackChunkName: "signin" */ "../views/Auth/SignIn.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/signup",
    name: "SignUp",
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/Auth/SignUp.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/otpreset/:id",
    name: "otpreset",
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/Auth/otpreset.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/confirm-signup",
    name: "ConfirmSignUp",
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    component: () =>
      import(
        /* webpackChunkName: "signup" */ "../views/Auth/ConfirmSignUp.vue"
      ),
    meta: { requiresVisitor: true },
  },
  {
    path: "/dashboard",
    components: {
      // StudioNavBar,
      NavBar,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      default: () =>
        import(/* webpackChunkName: "dashboard" */ "../views/Studio/Index.vue"),
    },
    children: [
      {
        path: "/",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */
            "../views/Studio/Dashboard.vue"
          ),
      },
      {
        path: "/notification",
        name: "Notification",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        component: () =>
          import(
            /* webpackChunkName: "video" */ "../views/Studio/Notification.vue"
          ),
      },
      {
        path: "/myvideo",
        name: "MyVideo",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        component: () =>
          import(/* webpackChunkName: "video" */ "../views/Studio/MyVideo.vue"),
      },
      {
        path: "/tagged",
        name: "Tagged",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        component: () =>
          import(/* webpackChunkName: "video" */ "../views/Studio/Tagged.vue"),
      },
      {
        path: "details/:id",
        name: "Detail",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        component: () =>
          import(/* webpackChunkName: "video" */ "../views/Studio/Details.vue"),
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/watch/:id",
    name: "Watch",
    components: {
      NavBar,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      default: () =>
        import(/* webpackChunkName: "video" */ "../views/Watch.vue"),
      Footer,
    },
  },
  {
    path: "/history",
    name: "History",
    components: {
      NavBar,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      default: () =>
        import(/* webpackChunkName: "video" */ "../views/History.vue"),
      Footer,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/search",
    name: "Search",
    components: {
      NavBar,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      default: () =>
        import(/* webpackChunkName: "video" */ "../views/Search.vue"),
      Footer,
    },
  },
  {
    path: "/inbox",
    name: "Inbox",
    components: {
      NavBar,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      default: () =>
        import(/* webpackChunkName: "about" */ "../views/Inbox.vue"),
      Footer,
    },
    meta: { requiresAuth: true },
  },

  /****   Start   ****/

  {
    path: "/",
    name: "Welcome",
    components: {
      NavBar,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      default: () =>
        import(/* webpackChunkName: "about" */ "../views/Welcome.vue"),
      Footer,
    },
    // meta: {requiresAuth: true}
    //    meta: {requiresVisitor: true}
  },

  /****   End   ****/
];

const router = new VueRouter({
  mode: "hash",
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  base: process.env.BASE_URL,
  routes,
});

const whiteList = []; // no redirect whitelist // '/signup', '/forgetpassword', '/otpreset/:id', '/confirm-signup'
router.beforeEach(async (to, from, next) => {
  const loggedIn = localStorage.getItem("user");

  if (loggedIn) {
    if (whiteList.indexOf(to.matched[0] ? to.matched[0].path : 0) !== 0) {
      next();
    } else {
      next({ path: "/signin" });
    }
  } else {
    if (whiteList.indexOf(to.matched[0] ? to.matched[0].path : 0) !== 0) {
      next();
    } else {
      next({ path: "/" });
    }
  }
});

export default router;
