const awsConfig = {
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'ap-south-1:391ddc39-1185-47d5-9883-87ac220ebb6d',

        // REQUIRED - Amazon Cognito Region
        region: 'ap-south-1',

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: '',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'ap-south-1_BrmT49kkD',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '2qf9g19nbptfpmt0se9407bhgb',

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - customized storage object
        storage: '',

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH',

        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        clientMetadata: { myCustomKey: 'myCustomValue' },

        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: 'your_cognito_domain',
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:8080/',
            redirectSignOut: 'http://localhost:8080/',
            headers: { 
                "Access-Control-Allow-Origin": "*",
              },
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    },
    API : {
        endpoints: [
            {
                name: "MyApiName",
                endpoint: "https://mpcmml9y6f.execute-api.ap-south-1.amazonaws.com/prod",
                headers: { 
                    "Access-Control-Allow-Origin": "*",
                  },
            }
        ]
    }
};

export default awsConfig;
