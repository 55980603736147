const state = {
  url: process.env.VUE_APP_URL,
  token: localStorage.getItem("token") || null,
  user: JSON.parse(localStorage.getItem("user")) || null,
  isUserLoggedIn: localStorage.getItem("token") || false,
};

const getters = {
  getUrl: (state) => {
    return state.url;
  },
  isAuthenticated: (state) => {
    return state.isUserLoggedIn;
  },
  getToken: (state) => {
    return state.token;
  },
  currentUser: (state) => {
    return state.user;
  },
};

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
    if (token) {
      state.isUserLoggedIn = true;
    } else {
      state.isUserLoggedIn = false;
    }
  },
  SET_USER_DATA(state, data) {
    state.user = data;
  },
  CLEAR_AUTH(state) {
    state.token = null;
    state.user = null;
    state.isUserLoggedIn = false;
  },
};

const actions = {
  setCurrentUser({ commit }, data) {
    localStorage.setItem("user", JSON.stringify(data.user));
    commit("SET_USER_DATA", data.user);

    localStorage.setItem("token", data.token);
    commit("SET_TOKEN", data.token);
  },
  signOut({ commit }) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    commit("CLEAR_AUTH");
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
