var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('router-view',{attrs:{"name":"NavBar"}}),_c('router-view',{attrs:{"name":"StudioNavBar"}}),_c('v-content',{class:{
      'content-bg': ![
        'SignIn',
        'SignUp',
        'Dashboard',
        'Video',
        'Detail',
        'Welcome',
      ].includes(this.$route.name)
        ? true
        : false,
    },staticStyle:{"background":"radial-gradient(rgb(230, 230, 230), transparent)"}},[_c('router-view')],1),_c('router-view',{attrs:{"name":"Footer"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }