import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {required, email, max, min, size, oneOf, confirmed } from 'vee-validate/dist/rules'
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from 'vee-validate'
import Vuebar from 'vuebar'

import ScrollBar from '@morioh/v-smooth-scrollbar'
import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("v-otp-input", OtpInput);
// global register
Vue.use(ScrollBar);

// import InfiniteLoading from 'vue-infinite-loading'

import Amplify from 'aws-amplify';
import awsConfig from './aws-exports';

Amplify.configure(awsConfig);

import '@aws-amplify/ui-vue';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import VideoPlayer from 'vue-vjs-hls'

// import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

// import AxiosPlugin from 'vue-axios-cors';
// Vue.config.productionTip = false
VideoPlayer.config({
  youtube: true,  // default false
  switcher: true, // default true
  hls: true       // default true
})
// Vue.use(AxiosPlugin({
//   origin: 'https://api.razorpay.com/v1/orders'
// }));
Vue.use(VideoPlayer)

setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'The {_field_} is required.'
})

extend('required_checkbox', {
  ...required,
  message: 'Accept to Proceed'
})

extend('oneOf', {
  ...oneOf
})

extend('confirmed', {
  ...confirmed,
  message: 'New Password and confirm password do not match'
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
})

extend('min', {
  ...min,
  message: '{_field_} may not be less than {length} characters'
})

extend('email', {
  ...email,
  message: 'Email must be valid'
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Password does not match'
})

extend('size', {
  ...size,
  message: 'video size should be less than 5 MB!'
})

Vue.config.productionTip = false
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)


// Vue.use(InfiniteLoading, {
//   props: {
//     distance: null
//     /* other props need to configure */
//   }
// })

// Vue.component('InfiniteLoading', InfiniteLoading)

Vue.use(Vuebar)

Vue.use(VueTelInputVuetify, {
  vuetify,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
