import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
    state: {
        confirmEmail: null
    },
    getters: {
        getConfirmEmail: (state) => {
            return state.confirmEmail
        }
    },
    mutations: {
        addConfirmEmail(state, confirmEmail) {
            state.confirmEmail = confirmEmail
        }
    },
}
