import Api from "@/services/Api";
import { Auth } from "aws-amplify";
import axios from "axios";

export default {
  async getTitles() {
    // const user = await Auth.currentAuthenticatedUser();
    return Api().get(
      "https://zpczn587s2.execute-api.ap-south-1.amazonaws.com/V1/search-city-locality",
      {
        data: "",
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      }
    );
  },

  async getLocation() {
    // const user = await Auth.currentAuthenticatedUser();
    return Api().get(
      // "https://zpczn587s2.execute-api.ap-south-1.amazonaws.com/V1/search-city-locality",
      "https://ch2mefwbpb.execute-api.ap-south-1.amazonaws.com/V1/locality",
      {
        data: "",
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      }
    );
  },

  async getCity() {
    // const user = await Auth.currentAuthenticatedUser();
    return Api().get(
      // "https://zpczn587s2.execute-api.ap-south-1.amazonaws.com/V1/search-city",
      "https://ch2mefwbpb.execute-api.ap-south-1.amazonaws.com/V1/search_v2",
      {
        data: "",
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      }
    );
  },
  async getSearchDataFromAPI(data, page) {
    console.log(page);
    return axios.get(
      "https://ch2mefwbpb.execute-api.ap-south-1.amazonaws.com/v1/search",
      {
        params: {
          type: data.type ? data.type : "All",
          city: data.city ? data.city : "",
          locationData: data.location ? data.location?.location : "",
          title: data.projectTitle ? data.projectTitle : "",
          // createTimeStamp: page?.createTimeStamp?.S,
          // videoId: page?.videoId?.S,
        },
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      }
    );
  },

  async getAll(page) {
    return axios.get(
      // "https://zpczn587s2.execute-api.ap-south-1.amazonaws.com/V1/video-search",
      "https://ch2mefwbpb.execute-api.ap-south-1.amazonaws.com/V1/all-video-list",
      {
        params: {
          createTimeStamp: page?.createTimeStamp?.S,
          videoId: page?.videoId?.S,
        },
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      }
    );
  },

  async deletevideo(videoId) {
    const user = await Auth.currentAuthenticatedUser();
    // const params = {
    //   createTimeStamp: videoId?.createTimeStamp,
    //   videoId: videoId?.videoId,
    // };

    return Api().get(
      `https://ch2mefwbpb.execute-api.ap-south-1.amazonaws.com/V1/deletevideo`,
      {
        params: {
          createTimeStamp: (videoId?.createTimeStamp).replace(/,/g, ""),
          videoId: videoId?.videoId,
        },
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
      }
    );
  },

  async getMyVideoList(page) {
    console.log(page);
    const user = await Auth.currentAuthenticatedUser();
    return Api().get(
      "https://ch2mefwbpb.execute-api.ap-south-1.amazonaws.com/V1/videolistbyuser",
      {
        params: {
          userId: user.username,
          createTimeStamp: page && page?.page?.createTimeStamp?.S,
          videoId: page && page?.page?.videoId?.S,
        },
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
      }
    );
  },

  async getTaggedVideo() {
    const user = await Auth.currentAuthenticatedUser();
    return Api().get(
      "https://zpczn587s2.execute-api.ap-south-1.amazonaws.com/V1/get-intrested-data",
      {
        params: {
          userId: user.username,
        },
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
      }
    );
  },

  // async getMyAllVideo() {
  //   const user = await Auth.currentAuthenticatedUser();
  //   return Api().get(
  //     "https://zpczn587s2.execute-api.ap-south-1.amazonaws.com/V1/get-intrested-data",
  //     {
  //       params: { userId: user.username },
  //     },
  //     {
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         "content-type": "application/json",
  //         "Access-Control-Allow-Credentials": true,
  //         Authorization: user.signInUserSession.idToken.jwtToken,
  //       },
  //     }
  //   );
  // },

  async getNotification() {
    // For Notification list get
    const user = await Auth.currentAuthenticatedUser();
    return Api().get(
      "https://ch2mefwbpb.execute-api.ap-south-1.amazonaws.com/V1/get-interested-users",
      {
        params: { userId: user.username },
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
      }
    );
  },

  async getMyVideo() {
    const user = await Auth.currentAuthenticatedUser();
    return Api().get(
      "https://ch2mefwbpb.execute-api.ap-south-1.amazonaws.com/V1/videolistbyuser",
      {
        data: user.username,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
      }
    );
  },

  getById(id) {
    return Api().get(`videos/${id}`);
  },
  async uploadVideoNow(url, data) {
    return await fetch(url, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      body: data, // body data type must match "Content-Type" header
    });
  },
  async uploadForm(formCollectedData) {
    console.log(formCollectedData, "test");
    const user = await Auth.currentAuthenticatedUser();
    return Api().post(
      "https://ch2mefwbpb.execute-api.ap-south-1.amazonaws.com/V1/uploadformdata",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
        body: JSON.stringify(formCollectedData),
      }
    );
  },
  async uploadFormAx(formCollectedData) {
    console.log("API GETWAY DATA");
    console.log(formCollectedData, "ax 1");
    const user = await Auth.currentAuthenticatedUser();

    return Api().post(
      "https://ch2mefwbpb.execute-api.ap-south-1.amazonaws.com/V1/mediauploadapi",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
        body: formCollectedData,
      }
    );
  },
  async uploadVideo(formCollectedData) {
    console.log(formCollectedData, "test", this.fileName);
    const user = await Auth.currentAuthenticatedUser();
    return Api().post(
      "https://5zyoi7qivj.execute-api.ap-south-1.amazonaws.com/v1",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
        body: {
          formData: {
            videoId: "sample-video-90.mp4",
            additional_information: "rajpur",
            amenities_attractions: "rajpur",
            area: "rajpur",
            availability: "availability",
            city: "rajpur",
            currentTimeStamp: "2023-03-15",
            details: "dfgdfgdfg",
            isUploadSuccessful: "",
            isValid: false,
            listing_type: "Agent",
            location: "rajpur",
            number_of_bedrooms: "2.5BHK",
            price: "5000",
            property_type: "Builder Project",
            sale_type: "Resale",
            title: "sanket - 1",
            userId: "axit.vnnovate@gmail.com",
          },
          mediaData: {
            file1: "1.jpg",
            file2: "1.jpg",
          },
        },
      }
    );
  },
  async uploadImage(formCollectedData) {
    console.log("ha", formCollectedData);
    const user = await Auth.currentAuthenticatedUser();
    return Api().post(
      "https://endwaituploads.s3.ap-south-1.amazonaws.com/efac6f3f-16a7-47be-8019-46e94f2fd92a/1.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVNI7F2S5GQNVEMFT%2F20230401%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230401T084930Z&X-Amz-Expires=1200&X-Amz-Signature=b609c2d41ffc49a3e77eac2369043328e583a1bf5f28daca65b8a218bb2d077f&X-Amz-SignedHeaders=host%3Bx-amz-acl&x-amz-acl=private",
      { file: formCollectedData },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
      }
    );
  },
  // async uploadImage() {
  //     const upload = multer({
  //         storage: multerS3({
  //             s3: s3,
  //             bucket: bucketName,
  //             metadata: function (req, file, cb) {
  //                 cb(null, {fieldName: file.fieldname});
  //             },
  //             key: function (req, file, cb) {
  //                 // let videoId = "1-min.png";
  //                 cb(null, videoIdFolder + file.originalname);
  //             },
  //         }),
  //     }).array("file", 15);
  //     return Api().post("/images", upload, (req, res) => {
  //         console.log("Image uploaded to S3 successfully.");
  //         res.send({ status: 200, data: "", msg: "image uploaded" });
  //         return {
  //             statusCode: 200,
  //             body: "Image uploaded to S3 successfully.",
  //         };
  //     });
  // },

  async getVideoCount() {
    const user = await Auth.currentAuthenticatedUser();

    return Api().post(
      "https://zpczn587s2.execute-api.ap-south-1.amazonaws.com/V1/check-user-video-limit",

      { user_id: user.username },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
      }
    );
  },

  async getOrder(order, price) {
    console.log("Amount", order, price);
    const user = await Auth.currentAuthenticatedUser();

    return Api().post(
      "https://ch2mefwbpb.execute-api.ap-south-1.amazonaws.com/V1/test",

      { user_id: user.username, amount: price },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
      }
    );
  },

  async getDifferentVideoAndImageList() {
    return Api().get(
      "https://zpczn587s2.execute-api.ap-south-1.amazonaws.com/V1/video-search",

      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      }
    );
  },

  // async getOrder() {
  //     const user = await Auth.currentAuthenticatedUser();
  //         const axiosInstance = axios.create({
  //             baseURL: `https://endnode.vnvserver.com`,
  //         })
  //         return axiosInstance.post('https://endnode.vnvserver.com', {"user_id":""},
  //             {
  //                 headers: {
  //                     'Access-Control-Allow-Origin': '*',
  //                     // 'content-type': 'application/json',
  //                     // 'Access-Control-Allow-Credentials': true,
  //                     'Authorization': user.signInUserSession.idToken.jwtToken
  //                     }
  //             }
  //         );
  // },
};
