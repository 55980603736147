<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="fab-transition"
    max-width="1000"
    v-scrollbar
  >
    <v-snackbar v-model="snackbar" :multi-line="multiLine" :timeout="timeout">
      {{ success }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-card style="padding: 0 0">
      <div class="d-flex justify-space-between" id="modal-header">
        <v-card-title class="py-3">
          <v-img
            :src="require('../assets/logo.png')"
            alt="EndWait"
            width="100"
          />
        </v-card-title>
        <div class="">
          <v-btn icon text @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <form
          @submit.prevent="handleSubmit(submit)"
          @reset.prevent="reset"
          ref="dataform"
          id="dataform"
          name="dataform"
        >
          <v-card-text v-if="!$store.getters.isAuthenticated">
            <v-card-text class="d-flex flex-column align-center">
              <!-- Your maximum limit of video upload is 0.Please subscribe to get
              more limit. -->
              <h4>
                Please sign in/create free account to gain access to all Endwait
                features
              </h4>
              <button @click="goToLogin" class="btn btn-success mt-2 p-2 px-3">
                Login
              </button>
              <!-- <fieldset class="border border-secondary mt-3 text-center p-2">
                <legend>
                  <small class="text-secondary">Video Quantity</small>
                </legend>
                <input
                  type="number"
                  id="count"
                  v-model="count"
                  class="mt-2 border border-secondary p-2"
                  placeholder="1"
                />
              </fieldset>
              <button @click="created()" class="btn btn-success mt-2 p-2 px-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-currency-rupee"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4v1.06Z"
                  />
                </svg>
                Pay Now
              </button> -->
            </v-card-text>
          </v-card-text>
          <v-card-text v-else>
            <v-card-text
              v-if="uploading"
              class="d-flex flex-column align-center"
            >
              <h3 class="mb-2">Step - 1/3</h3>
              <h2 class="mb-2">Property Details</h2>
              <ValidationObserver ref="form">
                <v-container class="grey lighten-5">
                  <v-row no-gutters>
                    <v-col
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                    >
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="title"
                        rules="required|min:4"
                      >
                        <v-text-field
                          v-model="formData.title"
                          :error-messages="errors"
                          label="Property Title (required)"
                          class="mb-1"
                          dense
                          outlined
                          counter="100"
                          max-length="100"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                    >
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="property detail"
                        rules="required|min:3"
                      >
                        <v-textarea
                          label="Property Detail (required)"
                          rows="3"
                          row-height="30"
                          v-model="formData.detail"
                          :error-messages="errors"
                          outlined
                        ></v-textarea>
                      </ValidationProvider>
                    </v-col>

                    <v-col
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="4"
                      lg="4"
                    >
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="property type"
                        rules="required|min:3"
                      >
                        <v-select
                          :items="propertyType"
                          :error-messages="errors"
                          outlined
                          dense
                          label="Property Type (required)"
                          v-model="formData.property_type"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>

                    <v-col
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="4"
                      lg="4"
                    >
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="sale_type"
                        rules="required|min:3"
                      >
                        <v-select
                          :items="saleType"
                          :error-messages="errors"
                          outlined
                          dense
                          label="Type (required)"
                          v-model="formData.sale_type"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>

                    <v-col
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="4"
                      lg="4"
                    >
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="city"
                        rules="required|min:3"
                      >
                        <v-text-field
                          v-model="formData.city"
                          :error-messages="errors"
                          label="City (required)"
                          class="mb-2"
                          dense
                          outlined
                          counter="100"
                          max-length="100"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="4"
                      lg="4"
                    >
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Locality"
                        rules="required|min:3"
                      >
                        <v-text-field
                          v-model="formData.location"
                          :error-messages="errors"
                          label="Locality (required)"
                          class="mb-3"
                          dense
                          outlined
                          counter="100"
                          max-length="100"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="4"
                      lg="4"
                    >
                      <v-select
                        :items="bedroom"
                        outlined
                        dense
                        label="Numbers Of Bedrooms"
                        v-model="formData.bedroom"
                      ></v-select>
                    </v-col>

                    <v-col
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="4"
                      lg="4"
                    >
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="area"
                        rules="required"
                      >
                        <v-text-field
                          v-model="formData.price"
                          label="Price"
                          name="price"
                          type="number"
                          :error-messages="errors"
                          class="mb-3"
                          dense
                          outlined
                          counter="100"
                          max-length="100"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="4"
                      lg="4"
                    >
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="area"
                        rules="required|min:3"
                      >
                        <v-text-field
                          v-model="formData.area"
                          :error-messages="errors"
                          label="Area (required)"
                          class="mb-3"
                          dense
                          outlined
                          counter="100"
                          max-length="100"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col
                      id="adate"
                      v-show="isShow"
                      :class="{ hide: !isShow }"
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="4"
                      lg="4"
                    >
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="formData.adate"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formData.adate"
                            label="Availability"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="formData.adate"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(formData.adate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="4"
                      lg="4"
                    >
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="ListType"
                        rules="required|min:3"
                      >
                        <v-select
                          :items="listType"
                          :error-messages="errors"
                          label="Listing Type (required)"
                          outlined
                          dense
                          v-model="formData.ListType"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                    <v-col
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                    >
                      <v-textarea
                        label="Amenities / Attractions"
                        rows="3"
                        row-height="30"
                        v-model="formData.amenities"
                        outlined
                      ></v-textarea>
                    </v-col>

                    <v-col
                      order="last"
                      order-sm="last"
                      order-md="first"
                      order-lg="first"
                      order-xl="first"
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                    >
                      <v-textarea
                        label="Additional Information"
                        rows="3"
                        row-height="30"
                        name="additional_information"
                        v-model="formData.additional_information"
                        outlined
                      ></v-textarea>
                    </v-col>

                    <div
                      class="col-sm-12 col-md-12 col-lg-12 order-sm-last order-md-first order-lg-first order-xl-first col-12 order-last"
                    >
                      <v-btn
                        :id="elementId"
                        :loading="submitLoading"
                        type="button"
                        @click="NextScreen()"
                        class="deep-orange white--text v-btn v-btn--has-bg theme--light v-size--default mt-4 mr-2"
                        depressed
                        >Next
                      </v-btn>
                      <v-btn
                        type="back"
                        depressed
                        @click="resetForm()"
                        class="deep-orange white--text v-btn v-btn--has-bg theme--light v-size--default mt-4"
                        >Reset
                      </v-btn>
                    </div>
                  </v-row>
                </v-container>
              </ValidationObserver>
            </v-card-text>
            <v-card-text v-if="uploading2">
              <h3 class="d-flex flex-column align-center mb-2">Step - 2/3</h3>
              <popup-modal
                v-if="showModal"
                @yes="uploadVideo"
                @no="showModal = false"
              >
                <h2>Are you sure you want to upload this video?</h2>
                <!--                                        <p>Video title: {{ videoTitle }}</p>-->
                <!--                                        <p>Video file: {{ videoFile.name }}</p>-->
              </popup-modal>
              <br />

              <div class="text-center">
                <v-container>
                  <ValidationProvider
                    rules="max:15|min:1|mimes:jpg,png,jpeg"
                    v-slot="{ errors }"
                    name="file"
                    ref="provider"
                  >
                    <v-file-input
                      accept="image/*"
                      prepend-icon="mdi-camera"
                      filled
                      label="Select files"
                      :rules="[requiredFiles]"
                      multiple
                      v-model="files"
                      @change="handleFileUpload"
                      :error-messages="errors"
                    >
                    </v-file-input>
                  </ValidationProvider>
                  <v-row v-if="imageUrls.length">
                    <v-col
                      sm="4"
                      v-for="(imageUrl, index) in imageUrls"
                      :key="index"
                    >
                      <!-- {{ file.name }}  -->
                      <!-- src="//placehold.it/400/99cc77" -->
                      <v-img
                        :ref="'file'"
                        :src="imageUrl"
                        class="img-fluid"
                        :title="'file' + index"
                        style="width: auto; height: 320px"
                        cover
                        alt=""
                      />
                    </v-col>
                  </v-row>
                </v-container>
                <v-btn
                  type="back"
                  depressed
                  @click="toggleShow1()"
                  class="deep-orange white--text v-btn v-btn--has-bg theme--light v-size--default v-btn v-btn--has-bg theme--light v-size--default mt-4 mr-2"
                  >Back
                </v-btn>
                <v-btn
                  type="button"
                  depressed
                  @click="resetForm2()"
                  class="deep-orange white--text v-btn v-btn--has-bg theme--light v-size--default v-btn v-btn--has-bg theme--light v-size--default mt-4 mr-2"
                  >Reset
                </v-btn>
                <v-btn
                  :disabled="nextButtonDisabled"
                  :id="elementId"
                  @click="midscreen()"
                  type="button"
                  depressed
                  class="deep-orange white--text v-btn v-btn--has-bg theme--light v-size--default v-btn v-btn--has-bg theme--light v-size--default mt-4 mr-2"
                  >NEXT
                </v-btn>
              </div>
            </v-card-text>
            <v-card-text
              v-if="uploading3"
              class="d-flex flex-column align-center"
            >
              <div v-if="!uploadings" class="text-center">
                <h3 class="mb-2">Step - 3/3</h3>
                <div class="text-center">
                  <div>
                    <v-btn
                      icon
                      class="grey lighten-2 mb-4"
                      style="height: 104px; width: 104px"
                      @click="selectFile"
                    >
                      <v-icon x-large class="grey--text text--darken-1">
                        mdi-upload
                      </v-icon>
                    </v-btn>
                  </div>
                  <ValidationProvider
                    rules="required|size:500000000|mimes:video/WEBM/MPG/MPEG/OGG/MP4/AVI/MOV/FLV/x-ms-wmv/x-msvideo/video/x-flv"
                    v-slot="{ errors }"
                    name="file"
                    ref="provider"
                  >
                    <v-file-input
                      style=""
                      type="file"
                      @change="uploadVideo"
                      accept="video/WEBM/MPG/MPEG/OGG/MP4/AVI/MOV/FLV/x-ms-wmv/x-msvideo/video/x-flv"
                      placeholder="Property Video"
                      outlined
                      dense
                      @click:clear="toggleShow()"
                      append-icon="mdi-video"
                      prepend-icon=""
                      :error-messages="errors"
                      ref="fileInput"
                    ></v-file-input>
                  </ValidationProvider>

                  <v-btn
                    :id="elementId"
                    @click="submit()"
                    type="button"
                    depressed
                    class="deep-orange white--text v-btn v-btn--has-bg theme--light v-size--default v-btn v-btn--has-bg theme--light v-size--default mt-4 mr-2"
                    >SUBMIT
                  </v-btn>
                  <v-btn
                    type="back"
                    depressed
                    @click="toggleShow2()"
                    class="deep-orange white--text v-btn v-btn--has-bg theme--light v-size--default v-btn v-btn--has-bg theme--light v-size--default mt-4"
                    >Back
                  </v-btn>
                </div>
              </div>
            </v-card-text>
            <div
              v-for="(progress, index) in progressArray"
              :key="index"
              width="75%"
            >
              <p>{{ progress.fileName }}</p>
              <!-- :height="10" -->
              <v-progress-linear
                :color="getProgressColor(progress)"
                v-if="progress.progress > 0"
                striped
                :value="progress.progress"
                @input="() => {}"
              ></v-progress-linear>
            </div>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>
<script>
//import myUpload from "vue-image-crop-upload";
import VideoService from "@/services/VideoService";
import VueSimpleAlert from "vue-simple-alert";
// import GenerateOrder from "@/services/GenerateOrder";
import { mapGetters } from "vuex";
// import PopupModal from '@/components/PopupModal.vue';
import axios from "axios";
import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import Compressor from "compressorjs";
// import { Auth } from "aws-amplify";

// import ImageInput from '@ /components/ImageInput.vue'
// import Razorpay from 'razorpay'
Vue.use(VueSimpleAlert);

export default {
  name: "UploadModal",
  props: ["openDialog", "videoLimit"],
  data: function () {
    return {
      // dialog: this.openDialog ? this.openDialog : false,
      value1: 33.333333333,
      max: 50,
      uuid: uuidv4(),
      valid: false,
      uploaded: false,
      uploadings: false,
      isValid: true,

      uploading: true, // for first screen
      uploading2: false, // for mid form
      uploading3: false,

      imageUrls: [],
      imageCount: false,
      submitLoading: false,
      categoryLoading: false,
      interval: {},
      count: 10,
      multiLine: true,
      snackbar: false,
      menu: "",
      success: "",
      value: 0,
      show: false,
      timeout: 5000,
      script: `https://checkout.razorpay.com/v1/checkout.js`,
      rules1: {
        detail: [
          { required: true, message: "Name is required", trigger: "change" },
        ],
        property_type: [
          { required: true, message: "Email is required", trigger: "change" },
        ],
        sale_type: [
          {
            required: true,
            message: "Password is required",
            trigger: "change",
          },
        ],
        city: [
          {
            required: true,
            message: "Lr Dealer Name is required",
            trigger: "change",
          },
        ],
        location: [
          {
            required: true,
            message: "Jag Dealer Name is required",
            trigger: "change",
          },
        ],
        bedroom: [
          {
            required: true,
            message: "Jag Dealer Name is required",
            trigger: "change",
          },
        ],
        price: [
          {
            required: true,
            message: "Jag Dealer Name is required",
            trigger: "change",
          },
        ],
        area: [
          {
            required: true,
            message: "Jag Dealer Name is required",
            trigger: "change",
          },
        ],
        adate: [
          {
            required: true,
            message: "Jag Dealer Name is required",
            trigger: "change",
          },
        ],
        ListType: [
          {
            required: true,
            message: "Jag Dealer Name is required",
            trigger: "change",
          },
        ],
        amenities: [
          {
            required: true,
            message: "Jag Dealer Name is required",
            trigger: "change",
          },
        ],
        additional_information: [
          {
            required: true,
            message: "Jag Dealer Name is required",
            trigger: "change",
          },
        ],
      },
      rules: [
        (value) =>
          !value ||
          value.size > 500000000 ||
          `Video size should be less than 5 MB!, ${value.size}`,
      ],
      //  categoriesTitles: [],
      //  categories: [],
      propertyType: [
        "Independent house",
        "Apartment",
        "Plot",
        "Villa",
        "Builder Project",
        "Office Space",
        "Showroom",
        "Commerical Land",
        "Other Commerical Property",
        "Agricultural Land",
        "Farm House",
      ],
      saleType: ["Newsale", "Resale", "Rent"],
      bedroom: [
        "1RK",
        "1BHK",
        "1.5BHK",
        "2BHK",
        "2.5BHK",
        "3BHK",
        "3.5BHK",
        "4BHK",
        "4+BHK",
        "Not Applicable",
      ],
      listType: ["Individual", "Agent", "Builder"],
      isShow: true,
      selectedFile: [],
      fileNames: [],
      uploadedImages: [],
      selectedFile1: [],
      property: "",
      fileData: "",
      files: [],
      previousValue: 0,
      progressArray: [],
      progress: 0,
      readers: [],
      formData2: [],
      fileUploadSingleForm: [],
      formData3: [],
      formData: {
        name: "",
        fileName: "",
        videoId: "",
        email: "",
        propertyType: "",
        saleType: "",
        title: "",
        detail: "",
        property_type: "",
        listingType: "",
        sale_type: "",
        city: "",
        userId: "",
        additionalInformation: "",
        location: "",
        bedroom: "",
        area: "",
        amenities: "",
        price: "",
        immediate: "",
        numberOfBedrooms: "",
        adate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        availability: "",
        ListType: "",
        additional_information: "",
        selectedFile: "",
        fileuploadformData: "",
        videoUploadPercentage: 0, // in use
        uploadPercentage: 0, // in use
        totalProgress: 0,
        counterData: 0,
      },
      //  imgDataUrl: "",
      url: "",
      headers: {
        Authorization: `Bearer ${this.$store.getters.getToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  },
  computed: {
    totalProgressOfVideos() {
      return Math.round(
        this.progressArray.reduce(
          (accumulator, progress) => accumulator + progress,
          0
        ) / this.files.length
      );
    },
    nextButtonDisabled() {
      return !this.imageCount;
    },
    dialog() {
      return this.openDialog;
    },
    elementId() {
      let uuid = uuidv4();
      console.log(uuid);
      // Generate a unique ID using the uuidv4() function
      return uuidv4();
    },
    ...mapGetters(["currentUser"]),
  },
  watch: {
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },
  },
  methods: {
    handleFileUpload(files) {
      // Get the selected files from the input element
      // this.selectedFiles = event.target.files;
      // this.selectedFile = event.target.files[0];
      this.readImageFiles(files);
    },
    readImageFiles(files) {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imageUrls.push(event.target.result);
        };
        reader.readAsDataURL(files[i]);
      }
    },
    async addFiles() {
      this.files.forEach((file, f) => {
        this.readers[f] = new FileReader();
        this.readers[f].onloadend = () => {
          let fileData = this.readers[f].result;
          let imgRef = this.$refs.file[f];
          imgRef.src = fileData;
          console.log(fileData);
          // send to server here...
        };
        this.readers[f].readAsDataURL(this.files[f]);
      });
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
    goToLogin() {
      this.$router.push("/signin");
    },
    async midscreen() {
      this.uploading = false;
      this.uploading2 = false;
      this.showModal = true;
      this.$fire({
        title: "Do you want to Video upload?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.uploading3 = true;
          this.showModal = true;
        } else {
          this.uploadFormData();

          // this.closeModal();
          // this.showModal = false;
          this.uploadings = true;
          this.uploading3 = true;
          setTimeout(() => {}, this.timeout);
        }
      });
    },

    async uploadFormData() {
      const fileNames = [];
      this.progressArray = Array(this.files.length).fill(0);
      for (let i = 0; i < this.files.length; i++) {
        fileNames.push(this.files[i].name);
      }
      if (this.fileData.name) {
        this.files.push(this.fileData);
        fileNames.push(this.fileData.name);
      }

      let fileNames2 = Object.values(fileNames);
      fileNames2 = fileNames2.reduce((obj, item, index) => {
        obj[index] = item;
        return obj;
      }, {});
      if (this.files.length > 15) {
        alert("You have to upload Maximum 15 Images");
        return false;
      }
      this.totalProgress = 0;
      this.files.forEach((key) => {
        this.totalProgress++;
        console.log("file Records List");
        console.log(key);
      });
      console.log("ax List name");
      console.log(fileNames2);
      console.log(this.isValid, "isValid");

      const data = {
        videoId: "sample-video-90.mp4",
        fileName: this.fileData.name,
        userId: this.currentUser.email,
        title: this.formData.title,
        details: this.formData.detail,
        property_type: this.formData.property_type,
        sale_type: this.formData.sale_type,
        city: this.formData.city, // city
        location: this.formData.location,
        number_of_bedrooms: this.formData.bedroom,
        area: this.formData.area, // 4
        amenities_attractions: this.formData.amenities, // 3
        price: this.formData.price,
        createTimeStamp: this.formData.adate, // 1
        availability: this.formData.adate, // availability
        listing_type: this.formData.ListType,
        isValid: this.isValid,
        user_name: this.currentUser.name,
        user_phone: this.currentUser.phone_number,
        additional_information: this.formData.additional_information, // 2
      };
      let arrayData1 = {
        formData: data,
        mediaData: fileNames2,
      };

      // console.log("conuter");
      // console.log("Total counter " + this.totalProgress);
      this.counterData = 1;
      this.value = 0;

      axios
        .post(
          "https://zpczn587s2.execute-api.ap-south-1.amazonaws.com/V1/mediaupload",
          arrayData1
        )
        .then(async (res) => {
          let uploadPromises;
          if (res.status == 200) {
            uploadPromises = this.files.map((file, index) =>
              this.uploadFile(file, res.data.data[index], index)
            );

            Promise.all(uploadPromises)
              .then(() => {
                this.$fire({
                  text: "Property Listing uploaded successfully.",
                }).then((r) => {
                  console.log(r.value);
                  this.resetForm();
                  this.resetForm2();
                  this.closeModal();
                  this.uploadings = false;
                  this.progressArray = [];
                });
              })
              .catch(() => {
                this.$fire({
                  text: "There was an error uploading the Property Listing, please re-try again",
                }).then((r) => {
                  console.log(r.value);
                  this.resetForm();
                  this.resetForm2();
                  this.closeModal();
                  this.uploadings = false;
                  this.progressArray = [];
                });
              });
          }
        })
        .catch(() => {
          this.$fire({
            text: "There was an error uploading the Property Listing, please re-try again",
          }).then((r) => {
            console.log(r.value);
            this.resetForm();
            this.resetForm2();
            this.closeModal();
            this.uploadings = false;
            this.progressArray = [];
          });
        });
    },
    uploadFile(file, key, index) {
      const isImage = file.type.startsWith("image/");
      const fileName = file.name;
      const self = this;

      return new Promise((resolve, reject) => {
        if (isImage) {
          new Compressor(file, {
            quality: 0.8,
            success(compressedFile) {
              const config = {
                headers: {
                  "Content-Type": "application/octet-stream",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Credentials": true,
                  "Cache-Control": "max-age=31536000",
                },
                onUploadProgress: (progressEvent) => {
                  const progress = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  self.progress = progress;
                  self.$set(self.progressArray, index, { progress, fileName });
                },
              };

              axios
                .put(`${key}`, compressedFile, config)
                .then(() => resolve())
                .catch((error) => reject(error));
            },
            error(err) {
              reject(err);
            },
          });
        } else {
          const config = {
            headers: {
              "Content-Type": "application/octet-stream",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Cache-Control": "max-age=31536000",
            },
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              self.progress = progress;
              self.$set(self.progressArray, index, { progress, fileName });
            },
          };

          axios
            .put(`${key}`, file, config)
            .then(() => resolve())
            .catch((error) => reject(error));
        }
      });
    },

    getProgressColor(progress) {
      if (progress === 100) {
        setTimeout(() => {
          location.reload();
        }, 4000);
        return "success";
      } else {
        return "#fb5723";
      }
    },

    async loadRazorPay() {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = this.script;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    },
    async created(order) {
      const result = await this.loadRazorPay();
      if (!result) {
        alert("Failed to load razorpay script");
        return;
      }
      await VideoService.getOrder(order, this.count * 5000)
        .then((res) => {
          //  console.log("hii k",res);
          var orderId = res.data.id;

          console.log("kartik2", res.data.id);

          if (this.count < 10) {
            alert("You have to purchace Minimum 10 Videos");
            return;
          }
          const options = {
            key: `rzp_test_523ZZXHXupaMCY`,
            amount: this.count * 5000,
            currency: `INR`,
            name: `EndWait`,
            mode: "no-cors",
            order_id: orderId,
            notes: {
              notes_key_1: "Tea, Earl Grey, Hot",
              notes_key_2: "Tea, Earl Grey… decaf.",
            },
            description: `Purchace Video Limit.`,
            handler: function () {
              // It is function executed on success
            },
            prefill: {
              email: this.currentUser.email,
              contact: this.currentUser.phone_number,
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
          console.log("Your Order Genrated Successfully!");
        })
        .catch((err) => {
          console.log("Error==>>", err.message);
          this.error = err.message;
        });

      setTimeout(() => {
        this.closeModal();
      }, this.timeout);
    },

    async uploadVideo(event) {
      console.log(event, "event");

      if (this.fileData) {
        console.log(this.fileData, "this.fileData");
        let videoFileType = this.fileData.type;
        if (this.fileData.type) {
          videoFileType = this.fileData.type.toLowerCase();
        }
        if (
          videoFileType === "video/quicktime" ||
          videoFileType === "video/x-flv" ||
          videoFileType === "video/x-msvideo" ||
          videoFileType === "video/x-ms-wmv" ||
          videoFileType === "video/webm" ||
          videoFileType === "video/mpg" ||
          videoFileType === "video/mpeg" ||
          videoFileType === "video/ogg" ||
          videoFileType === "video/mp4" ||
          videoFileType === "video/avi" ||
          videoFileType === "video/mov" ||
          videoFileType === "video/flv"
        ) {
          this.success = "Property video file is required";
          this.snackbar = true;
        }
      }
      this.fileData = await event;
      this.uploading = false;
    },

    async uploadVideoNow() {
      await VideoService.uploadVideoNow(this.fileData)
        .then(() => {
          this.uploaded = false;
          this.uploading = false;
          this.uploading2 = false;
          this.uploading3 = true;
          this.submitLoading = true;
          this.snackbar = true;
          this.selectedFile = [];

          this.success =
            "Video received successfully and will be available to EndWait users once the content is validated. Please reach out to EndWait customer service at contact@endwait.com for any questions";
          this.snackbar = true;
          console.log("videob", this.files);
          setTimeout(() => {
            this.closeModal();
          }, this.timeout);
          this.resetForm();
        })
        .catch((err) => {
          this.error = err.message;
        });
    },
    async uploadImageNow(s3PresignedUrl) {
      await VideoService.uploadImageNow(s3PresignedUrl, this.file)
        .then(() => {
          this.uploaded = false;
          this.uploading = false;
          this.uploading2 = true;
          this.uploading3 = false;
          this.submitLoading = false;
          this.snackbar = true;
          this.selectedFile1 = [];
          this.success = "Image uploaded successfully.";
          this.snackbar = true;
          console.log("videof", this.files);
          setTimeout(() => {
            this.closeModal();
          }, this.timeout);
          this.resetForm();
        })
        .catch((err) => {
          this.error = err.message;
        });
    },

    async submit() {
      this.uploaded = false;
      this.uploading = false;
      this.uploading2 = false;
      this.uploading3 = true; // for show loader
      this.submitLoading = false;
      this.uploadings = true;
      this.isValid = false;
      let formData3 = new FormData();
      formData3.append("file", this.fileData.name);
      console.log(this.fileData.name + " File name");
      console.log(JSON.stringify(this.fileData) + " File content");
      await this.uploadFormData();
      // this.uploading3 = false; // for show loader

      /*
         axios.post('https://5zyoi7qivj.execute-api.ap-south-1.amazonaws.com/v1', formData3).then(res => {
           this.uploadings = true;
           // console.log(res.status,'res')
           if (res.status == 200) {
             this.uploadings = true;
             // console.log(res.status)
             this.snackbar = true
             this.success = "Video received successfully and will be available to EndWait users once the content is validated. Please reach out to EndWait customer service at contact@endwait.com for any questions";
             // console.log('videob',this.files)
             setTimeout(() => {
               this.closeModal();

             }, this.timeout);
           }
         }).catch((err) => {
           console.log(err.response)
         })
   */
      this.resetForm();
    },
    closeModal() {
      this.uploaded = false;
      // this.uploading = true;
      this.uploading = true;
      this.uploading2 = false;
      this.uploading3 = false;
      this.$emit("closeDialog");
      // this.resetForm();
    },
    selectFile() {
      this.$refs.fileInput.$refs.input.click();
    },
    toggleShow() {
      this.fileData = "";
      this.file = "";
      // this.formData.title = "";
      this.uploaded = true;
      this.uploading = true;
      this.uploading2 = false;
      this.uploading3 = true;
      this.resetForm();
    },
    toggleShow1() {
      this.fileData = "";
      this.submitLoading = false;
      this.uploaded = false;
      this.uploading = true;
      this.uploading2 = false;
      this.uploading3 = false;
      this.resetForm();
    },
    toggleShow2() {
      this.fileData = "";
      this.uploaded = false;
      this.uploading = false;
      this.uploading2 = true;
      this.uploading3 = false;
      this.showModal = false;
      this.resetForm();
    },
    resetForm() {
      this.fileData = "";
      this.file = "";
      this.formData.createTimeStamp = "";
      this.formData.title = "";
      this.formData.ListType = "";
      this.formData.price = "";
      this.formData.email = "";
      this.formData.detail = "";
      this.formData.property_type = "";
      this.formData.adate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.formData.sale_type = "";
      this.formData.city = "";
      this.formData.currentTimeStamp = "";
      this.formData.location = "";
      this.formData.bedroom = "";
      this.formData.area = "";
      this.formData.amenities_attractions = "";
      this.formData.amenities = "";
      this.formData.availability = "";
      this.formData.additional_information = "";
      this.formData.isUploadSuccessful = "";
      this.isValid = true;
      this.submitLoading = false;
    },
    // resetForm() {
    //   this.fileData = "";
    //   this.file = "";
    //   this.formData.createTimeStamp = "";
    //   this.formData.title = "";
    //   this.formData.listing_type = "";
    //   this.formData.price = "";
    //   this.formData.email = "";
    //   this.formData.details = "";
    //   this.formData.property_type = "";
    //   this.formData.adate = new Date(
    //     Date.now() - new Date().getTimezoneOffset() * 60000
    //   )
    //     .toISOString()
    //     .substr(0, 10);
    //   this.formData.sale_type = "";
    //   this.formData.city = "";
    //   this.formData.currentTimeStamp = "";
    //   this.formData.location = "";
    //   this.formData.number_of_bedrooms = "";
    //   this.formData.area = "";
    //   this.formData.amenities_attractions = "";
    //   this.formData.availability = "";
    //   this.formData.additional_information = "";
    //   this.formData.isUploadSuccessful = "";
    //   this.formData.isValid = "";
    //   this.submitLoading = false;
    // },
    resetForm2() {
      this.fileData = "";
      this.files = "";
      this.uploading = false;
      this.uploading3 = false;
      this.uploading2 = true;
      this.imageUrls = [];
    },
    async NextScreen() {
      if (this.formData.title == "") {
        this.success = "Title field is required";
        this.snackbar = true;
      } else if (this.formData.detail == "") {
        this.success = "Detail field is required";
        this.snackbar = true;
      } else if (this.formData.property_type == "") {
        this.success = "Property Type field is required";
        this.snackbar = true;
      } else if (this.formData.sale_type == "") {
        this.success = "Sale Type field is required";
        this.snackbar = true;
      } else if (this.formData.city == "") {
        this.success = "City field is required";
        this.snackbar = true;
      } else if (this.formData.location == "") {
        this.success = "Location field is required";
        this.snackbar = true;
      } else if (this.formData.bedroom == "") {
        this.success = "Bedroom field is required";
        this.snackbar = true;
      } else if (this.formData.price == "") {
        this.success = "Price field is required";
        this.snackbar = true;
      } else if (this.formData.area == "") {
        this.success = "Area field is required";
        this.snackbar = true;
      } else if (this.formData.adate == "") {
        this.success = "Date field is required";
        this.snackbar = true;
      } else if (this.formData.ListType == "") {
        this.success = "List Type field is required";
        this.snackbar = true;

        // } else if (this.formData.Amenities == "") {
        //   this.success = "Amenities field is required";
        //   this.snackbar = true;
        // } else if (this.formData.additional_information == "") {
        //   this.success = "Additional Information field is required";
        //   this.snackbar = true;
      } else {
        this.fileData = "";
        this.files = "";
        this.uploaded = true;
        this.submitLoading = true;
        this.uploading = false;
        this.uploading3 = false;
        this.uploading2 = true;
      }

      // this.fileData = "";
      // this.files = "";
      // this.uploaded = true;
      // this.submitLoading = true;
      // this.uploading = false;
      // this.uploading3 = false;
      // this.uploading2 = true;
    },
    requiredFiles(value) {
      if (!value || value.length < 1) {
        this.imageCount = false;

        return "Please select at least one file";
      } else {
        this.imageCount = true;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.custom-field {
  height: 40px;
}
</style>
<style scoped>
/* Adjustments to v-progress-circular */
.v-progress-circular__info {
  font-size: 14px;
  font-weight: bold;
}

.v-progress-circular__content {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style>
.v-dialog {
  overflow: auto !important;
}

.v-progress-linear {
  height: 8px !important;
  margin-top: -5px;
}
</style>
