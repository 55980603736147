<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="fab-transition"
    max-width="800"
    id="settings"
    style="z-index: 20001"
  >
    <v-card tile>
      <div class="d-flex justify-space-between mb-5" id="modal-header">
        <v-card-title class="py-3">
          <v-img
            :src="require('../assets/logo.png')"
            alt="EndWait"
            width="100"
          />
        </v-card-title>

        <v-btn icon text @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-snackbar v-model="snackbar" :multi-line="multiLine">
        {{ success }}

        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-card-text>
        <v-row>
          <v-col
            order="last"
            order-sm="last"
            order-md="first"
            order-lg="first"
            order-xl="first"
            cols="12"
            sm="12"
            md="8"
            lg="8"
            class="pt-0"
          >
            <h3 class="mb-6 mt-0 pt-0">Personal Information</h3>
            <ValidationObserver
              ref="personalInfoForm"
              v-slot="{ handleSubmit, reset }"
            >
              <form
                @submit.prevent="handleSubmit(submit2)"
                @reset.prevent="reset"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Name"
                  rules="required|min:3"
                >
                  <v-text-field
                    v-model="formData.name"
                    :error-messages="errors"
                    label="Name"
                    outlined
                    dense
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="formData.email"
                    :error-messages="errors"
                    label="Email"
                    outlined
                    disabledname
                    dense
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="phone"
                  rules="required|phone"
                >
                  <vue-tel-input-vuetify
                    v-model="formData.phone"
                    :error-messages="errors"
                    label="Phone"
                    mode="international"
                    disabled
                    outlined
                    dense
                  ></vue-tel-input-vuetify>
                </ValidationProvider>

                <v-btn
                  :loading="loading.personalInfo"
                  type="submit"
                  class="deep-orange white--text"
                  depressed
                  >Update Profile
                </v-btn>
              </form>
            </ValidationObserver>

            <h3 class="mb-6 mt-6">Change Password</h3>
            <ValidationObserver
              ref="passwordForm"
              v-slot="{ handleSubmit, reset }"
            >
              <form
                @submit.prevent="handleSubmit(submitPassword)"
                @reset.prevent="reset"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Current password"
                  rules="required|min:5"
                >
                  <v-text-field
                    v-model="formData.currentPassword"
                    :append-icon="
                      showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :error-messages="errors"
                    :type="showCurrentPassword ? 'text' : 'password'"
                    name="Current password"
                    label="Current Password"
                    outlined
                    dense
                    hint="At least 5 characters"
                    class="input-group--focused"
                    @click:append="showCurrentPassword = !showCurrentPassword"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="New password"
                  rules="required|min:5"
                >
                  <v-text-field
                    v-model="formData.newPassword"
                    :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :error-messages="errors"
                    :type="showNewPassword ? 'text' : 'password'"
                    name="New password"
                    label="New Password"
                    outlined
                    dense
                    hint="At least 5 characters"
                    class="input-group--focused"
                    @click:append="showNewPassword = !showNewPassword"
                  ></v-text-field>
                </ValidationProvider>

                <v-btn
                  :loading="loading.password"
                  type="submit"
                  class="deep-orange white--text"
                  depressed
                  >Update Password
                </v-btn>
              </form>
            </ValidationObserver>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";
import { extend } from "vee-validate";
import PhoneNumber from "awesome-phonenumber";
import { Auth } from "aws-amplify";

extend("phone", {
  message(fieldName) {
    return `${fieldName} is not a valid phone number`;
  },
  validate(value) {
    return new Promise((resolve) => {
      let phone = new PhoneNumber(value);
      resolve({ valid: phone.isValid() });
    });
  },
});

export default {
  name: "SettingsModal",
  props: ["openDialog"],
  data: function () {
    return {
      showCurrentPassword: false,
      showNewPassword: false,
      valid: false,
      interval: {},
      multiLine: true,
      snackbar: false,
      success: "",
      value: 0,
      timeout: 5000,
      show: false,
      rules: [
        (value) =>
          !value ||
          value.size < 5000000 ||
          "Video size should be less than 5 MB!",
      ],
      visibilty: ["Public", "Private"],
      formData: {
        name: this.$store.getters.currentUser.name,
        email: this.$store.getters.currentUser.email,
        phone: this.$store.getters.currentUser.phone_number,
        currentPassword: "",
        newPassword: "",
      },
      loading: {
        personalInfo: false,
        password: false,
      },
      url: `${process.env.VUE_APP_URL}/api/v1/auth/avatar`,
      headers: { Authorization: `Bearer ${this.$store.getters.getToken}` },
    };
  },
  created() {
    this.name = Auth.user.attributes.name;
  },
  computed: {
    dialog() {
      return this.openDialog;
    },
  },
  methods: {
    async submit2() {
      this.success = "Profile update successfully!";
      //   if (this.loading.personalInfo) return;
      //   this.loading.personalInfo = true;
      try {
        this.loading.personalInfo = true;
        await AuthenticationService.UpdateUser({
          userId: this.formData.email,
          phoneNumber: this.formData.phone,
          emailAddress: this.formData.email,
          pushToken: this.$store.getters.getToken,
          name: this.formData.name,
          appVersion: "1.0",
          deviceType: "PC",
          deviceOs: "Window",
        }).then(async () => {
          this.snackbar = true;
          const user = await Auth.currentAuthenticatedUser();
          await Auth.updateUserAttributes(user, {
            name: this.formData.name,
          });

          let userRec = await Auth.currentAuthenticatedUser();

          await Auth.updateUserAttributes(userRec, {
            email: this.formData.email,
            name: this.formData.name,
          });
          this.currentUser.name = this.formData.name;
          // console.log(result); // SUCCESS
          // Auth.currentSession()
          //     .then(data => console.log(data))
          //     .catch(err => console.log(err));
        });
      } catch (error) {
        //     this.error = error.message;
        //    this.snackbar = true;
      }
      this.loading.personalInfo = false;
      //    this.loading.personalInfo = false;
      this.closeModal();
    },
    async submitPassword() {
      this.success = "Password updated successfully!";
      if (this.loading.password) return;

      this.loading.password = true;

      await AuthenticationService.updatePassword({
        currentPassword: this.formData.currentPassword,
        newPassword: this.formData.newPassword,
      })
        .then(() => {
          this.snackbar = true;
          this.loading.password = false;
          this.loading.password = false;
          this.formData.currentPassword = "";
          this.formData.newPassword = "";
          setTimeout(() => {
            this.closeModal();
          }, this.timeout);
        })
        .catch((err) => {
          if (err.code === "NotAuthorizedException") {
            this.success = "Entered current password is incorrect";
          } else {
            this.success = err.message;
          }

          this.snackbar = true;
          this.loading.password = false;
        })
        .finally(() => (this.loading.password = false));
      this.$refs.passwordForm.reset();
    },
    closeModal() {
      this.$emit("closeDialog");
      this.$refs.passwordForm.reset();
    },
    toggleShow() {
      setTimeout(() => {
        this.closeModal();
      }, this.timeout);
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss">
#modal-header {
  border-bottom: 1px solid rgb(238, 232, 232);
}

#settings-image-placeholder {
  padding-top: 7em;
  padding-bottom: 7em;
  border: 2px dashed rgb(209, 209, 209);
  border-radius: 50%;
}

.v-input__slot {
  height: 20px;
}

@media (max-width: 700px) {
  .vue-image-crop-upload {
    .vicp-wrap {
      width: 95%;
      height: 550px;

      .vicp-step2 .vicp-crop {
        .vicp-crop-left {
          float: none;

          .vicp-range {
            margin: auto;
            margin-top: 30px;
          }

          & * {
            margin-left: auto;
            margin-right: auto;
          }
        }

        .vicp-crop-right {
          float: none;
          margin-top: 30px;

          .vicp-preview .vicp-preview-item {
            display: inline-block;
            float: none;
          }

          & * {
            // margin-left: auto;
            // margin-right: auto;
          }
        }
      }
    }
  }
}
</style>
