import axios from "axios";
import { Auth } from "aws-amplify";

export default () => {
  const axiosInstance = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/v1`,
  });

  const token = localStorage.getItem("token");
  //const userData = localStorage.getItem('user')

  if (token) {
    const getUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        // console.log("user " + JSON.stringify(user.username));
        const userToken = localStorage.getItem(
          `CognitoIdentityServiceProvider.2qf9g19nbptfpmt0se9407bhgb.${user.username}.idToken`
        );
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${userToken}`;
      } catch (error) {
        console.log("Error getting user", error);
      }
    };

    getUser();
  }

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log(error);
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        if (this.$route.name !== "Welcome") {
          location.reload();
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
