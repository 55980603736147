<template>
  <nav id="navbar">
    <!-- <v-progress-circular
      v-if="loading"
      indeterminate
      size="64"
      color="primary"
    ></v-progress-circular> -->
    <v-app-bar class="white header" flat app clipped-left>
      <!-- logo -->
      <v-toolbar-title class="header-logo">
        <router-link to="/" class="black--text" style="text-decoration: none">
          <v-img
            :src="require('../assets/logo.png')"
            alt="EndWait"
            width="100"
            @click="reloadPage"
          />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-container> -->
      <!-- search bar and drop downs -->

      <!-- v-if="this.$route.path == '/'" -->
      <v-row class="hidden-sm-and-down" width="25%" style="margin-left: 2%">
        <!-- <v-col cols="1"></v-col> -->
        <v-col cols="2" class="my-col-1 d-flex">
          <v-select
            flat
            solo
            v-model="type"
            :items="typeItems"
            item-value="Type"
            item-text="Type"
            label="Type"
            color="deep-orange deep-orange--text p-0"
            class="rounded cursor"
            hide-details
            dense
            clearable
          ></v-select>
          <div class="middle-line"></div>
        </v-col>
        <!-- @change="onSelectionChanged" -->
        <!-- outlined -->
        <v-col cols="2" class="my-col-2 d-flex">
          <v-select
            flat
            solo
            v-model="city"
            :items="cityItems"
            item-value="city"
            item-text="city"
            label="City"
            color="deep-orange deep-orange--text p-0"
            class="rounded cursor"
            hide-details
            @change="onSelectionChanged"
            dense
            clearable
          ></v-select>
          <!-- outlined -->
          <div class="middle-line"></div>
        </v-col>
        <v-col cols="2" class="my-col-2 d-flex">
          <v-combobox
            flat
            solo
            v-model="location"
            :items="filteredLocality"
            item-value="location"
            item-text="location"
            label="Locality"
            color="deep-orange deep-orange--text"
            class="cursor"
            hide-details
            dense
            clearable
          ></v-combobox>
          <div class="middle-line"></div>

          <!-- outlined -->
        </v-col>
        <v-col cols="4" class="my-col-3">
          <!-- append-icon="mdi-magnify" -->
          <v-text-field
            flat
            solo
            v-model="projectTitle"
            :items="titleItems"
            item-value="Locality"
            item-text="Locality"
            label="Project Title"
            color="#f3a288"
            class="rounded cursor"
            hide-details
            dense
            clearable
          >
            <!-- @input="searchMe" -->
            <template v-slot:append>
              <!-- search button svg -->
              <svg
                width="30"
                height="30"
                viewBox="0 0 37 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="cursor"
                @click="searchMe"
              >
                <rect width="37" height="34.2927" rx="3.22729" fill="#FBA388" />
                <path
                  d="M27.6 26L21.3 19.7C20.8 20.1 20.225 20.4167 19.575 20.65C18.925 20.8833 18.2333 21 17.5 21C15.6833 21 14.146 20.371 12.888 19.113C11.6293 17.8543 11 16.3167 11 14.5C11 12.6833 11.6293 11.1457 12.888 9.887C14.146 8.629 15.6833 8 17.5 8C19.3167 8 20.8543 8.629 22.113 9.887C23.371 11.1457 24 12.6833 24 14.5C24 15.2333 23.8833 15.925 23.65 16.575C23.4167 17.225 23.1 17.8 22.7 18.3L29 24.6L27.6 26ZM17.5 19C18.75 19 19.8127 18.5627 20.688 17.688C21.5627 16.8127 22 15.75 22 14.5C22 13.25 21.5627 12.1873 20.688 11.312C19.8127 10.4373 18.75 10 17.5 10C16.25 10 15.1873 10.4373 14.312 11.312C13.4373 12.1873 13 13.25 13 14.5C13 15.75 13.4373 16.8127 14.312 17.688C15.1873 18.5627 16.25 19 17.5 19Z"
                  fill="white"
                />
              </svg>
            </template>
          </v-text-field>
          <!-- outlined -->
        </v-col>
      </v-row>
      <!-- </v-container> -->
      <v-spacer></v-spacer>
      <!-- svg icons for search, upload video, notification, history and login based on responsive screens -->
      <v-row
        style="margin-top: -12px; margin-right: 1%"
        class="header-icon"
        width="10%"
        justify="end"
      >
        <!-- search button svg for small devices -->
        <v-col cols="1" class="hidden-md-and-up">
          <svg
            width="24"
            height="24"
            viewBox="0 0 36 36"
            class="cursor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="searchBar = true"
          >
            <path
              d="M33.2 36L20.6 23.4C19.6 24.2 18.45 24.8333 17.15 25.3C15.85 25.7667 14.4667 26 13 26C9.36667 26 6.292 24.742 3.776 22.226C1.25867 19.7087 0 16.6333 0 13C0 9.36667 1.25867 6.29133 3.776 3.774C6.292 1.258 9.36667 0 13 0C16.6333 0 19.7087 1.258 22.226 3.774C24.742 6.29133 26 9.36667 26 13C26 14.4667 25.7667 15.85 25.3 17.15C24.8333 18.45 24.2 19.6 23.4 20.6L36 33.2L33.2 36ZM13 22C15.5 22 17.6253 21.1253 19.376 19.376C21.1253 17.6253 22 15.5 22 13C22 10.5 21.1253 8.37467 19.376 6.624C17.6253 4.87467 15.5 4 13 4C10.5 4 8.37467 4.87467 6.624 6.624C4.87467 8.37467 4 10.5 4 13C4 15.5 4.87467 17.6253 6.624 19.376C8.37467 21.1253 10.5 22 13 22Z"
              fill="#79869F"
            />
          </svg>
        </v-col>
        <!-- upload video svg -->
        <!-- v-if="$store.getters.isAuthenticated" -->
        <v-col cols="1" class="mx-xs-auto" style="padding-left: 1.5rem">
          <svg
            width="24"
            height="24"
            class="cursor"
            viewBox="0 0 36 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="modal"
          >
            <path
              d="M33.625 5.80859L28.625 8.6875V2.75C28.625 1.37109 27.5039 0.25 26.125 0.25H3C1.62109 0.25 0.5 1.37109 0.5 2.75V25.25C0.5 26.6289 1.62109 27.75 3 27.75H26.125C27.5039 27.75 28.625 26.6289 28.625 25.25V19.3125L33.625 22.1914C34.457 22.6719 35.5 22.0703 35.5 21.1133V6.89062C35.5 5.92969 34.457 5.32812 33.625 5.80859ZM25.8125 24.9375H3.3125V3.0625H25.8125V24.9375ZM32.6875 18.4141L28.625 16.0781V11.9258L32.6875 9.58594V18.4141ZM6.125 8.0625H10.5C10.6719 8.0625 10.8125 7.92188 10.8125 7.75V5.875C10.8125 5.70312 10.6719 5.5625 10.5 5.5625H6.125C5.95312 5.5625 5.8125 5.70312 5.8125 5.875V7.75C5.8125 7.92188 5.95312 8.0625 6.125 8.0625Z"
              fill="#79869F"
            />
          </svg>
        </v-col>
        <!-- notification svg -->
        <!-- v-if="$store.getters.isAuthenticated" -->
        <v-col
          v-if="$store.getters.isAuthenticated"
          cols="1"
          class="mx-xs-auto"
          style="padding-left: 1.5rem"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 30 34"
            class="cursor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="goToNotification"
          >
            <path
              d="M21.2498 32C21.2498 32.3315 21.1181 32.6495 20.8837 32.8839C20.6493 33.1183 20.3313 33.25 19.9998 33.25H9.99979C9.66827 33.25 9.35033 33.1183 9.11591 32.8839C8.88149 32.6495 8.74979 32.3315 8.74979 32C8.74979 31.6685 8.88149 31.3505 9.11591 31.1161C9.35033 30.8817 9.66827 30.75 9.99979 30.75H19.9998C20.3313 30.75 20.6493 30.8817 20.8837 31.1161C21.1181 31.3505 21.2498 31.6685 21.2498 32ZM29.5154 27C29.2967 27.3821 28.9803 27.6993 28.5986 27.9189C28.217 28.1385 27.7839 28.2528 27.3435 28.25H2.65604C2.21565 28.25 1.78305 28.1338 1.40203 27.9129C1.02102 27.6921 0.705103 27.3745 0.486263 26.9923C0.267423 26.6101 0.153422 26.1769 0.155798 25.7365C0.158174 25.2961 0.276843 24.8642 0.499795 24.4844C1.42167 22.8906 2.53104 19.8594 2.53104 14.5V13.25C2.52899 11.6105 2.84988 9.98671 3.47538 8.47125C4.10088 6.95579 5.01875 5.57837 6.17658 4.41763C7.33441 3.2569 8.70953 2.33559 10.2234 1.70629C11.7373 1.077 13.3603 0.752051 14.9998 0.75H15.0935C21.9217 0.796875 27.4685 6.46875 27.4685 13.3906V14.5C27.4685 19.8594 28.5779 22.8906 29.4998 24.4844C29.7215 24.8659 29.8395 25.2988 29.8422 25.7401C29.845 26.1814 29.7323 26.6157 29.5154 27ZM27.3435 25.75C26.2654 23.875 24.9685 20.4219 24.9685 14.5V13.3906C24.9685 7.84375 20.531 3.28125 15.0779 3.25H14.9998C13.688 3.24999 12.3892 3.50887 11.1777 4.0118C9.96618 4.51473 8.86585 5.25182 7.93975 6.18082C7.01366 7.10982 6.28002 8.21245 5.78088 9.42553C5.28175 10.6386 5.02693 11.9383 5.03104 13.25V14.5C5.03104 20.4219 3.73417 23.875 2.65604 25.75H27.3435Z"
              fill="#79869F"
            />
          </svg>
        </v-col>
        <!-- tagged  clock svg -->
        <!-- v-if="$store.getters.isAuthenticated" -->
        <v-col
          v-if="$store.getters.isAuthenticated"
          cols="1"
          class="mx-xs-auto"
          style="padding-left: 1.5rem"
        >
          <svg
            width="24"
            height="24"
            class="cursor"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="goToTagged"
          >
            <path
              d="M19.9835 3.33301C10.7835 3.33301 3.3335 10.7997 3.3335 19.9997C3.3335 29.1997 10.7835 36.6663 19.9835 36.6663C29.2002 36.6663 36.6668 29.1997 36.6668 19.9997C36.6668 10.7997 29.2002 3.33301 19.9835 3.33301ZM20.0002 33.333C12.6335 33.333 6.66683 27.3663 6.66683 19.9997C6.66683 12.633 12.6335 6.66634 20.0002 6.66634C27.3668 6.66634 33.3335 12.633 33.3335 19.9997C33.3335 27.3663 27.3668 33.333 20.0002 33.333Z"
              fill="#79869F"
            />
            <path
              d="M20.8335 11.667H18.3335V21.667L27.0835 26.917L28.3335 24.867L20.8335 20.417V11.667Z"
              fill="#79869F"
            />
          </svg>
        </v-col>
        <!-- login svg for small devices -->
        <v-col
          cols="1"
          class="mx-xs-auto hidden-md-and-up"
          style="padding-left: 1.5rem"
          v-if="!$store.getters.isAuthenticated"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 33 30"
            class="cursor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="goToLogin"
          >
            <path
              d="M13.7249 29.25C13.347 29.25 12.9845 29.0999 12.7173 28.8326C12.45 28.5654 12.2999 28.2029 12.2999 27.825C12.2999 27.4471 12.45 27.0846 12.7173 26.8174C12.9845 26.5501 13.347 26.4 13.7249 26.4H25.5999C26.6077 26.4 27.5743 25.9996 28.2869 25.287C28.9995 24.5744 29.3999 23.6078 29.3999 22.6V7.4C29.3999 6.39218 28.9995 5.42563 28.2869 4.71299C27.5743 4.00036 26.6077 3.6 25.5999 3.6H13.7249C13.347 3.6 12.9845 3.44987 12.7173 3.18263C12.45 2.91539 12.2999 2.55293 12.2999 2.175C12.2999 1.79707 12.45 1.43461 12.7173 1.16737C12.9845 0.900133 13.347 0.75 13.7249 0.75H25.5999C27.3636 0.75 29.055 1.45062 30.3022 2.69774C31.5493 3.94486 32.2499 5.63631 32.2499 7.4V22.6C32.2499 24.3637 31.5493 26.0551 30.3022 27.3023C29.055 28.5494 27.3636 29.25 25.5999 29.25H13.7249ZM13.7078 7.7781C13.8453 7.65107 14.0064 7.55237 14.182 7.48763C14.3577 7.42289 14.5443 7.39338 14.7313 7.40079C14.9184 7.4082 15.1021 7.45239 15.272 7.53082C15.442 7.60925 15.5948 7.72039 15.7218 7.8579L21.4218 14.0329C21.6651 14.2962 21.8002 14.6415 21.8002 15C21.8002 15.3585 21.6651 15.7038 21.4218 15.9671L15.7218 22.1421C15.5947 22.2795 15.4417 22.3904 15.2717 22.4687C15.1017 22.5469 14.918 22.5909 14.7309 22.5982C14.5439 22.6054 14.3573 22.5757 14.1818 22.5108C14.0062 22.446 13.8452 22.3471 13.7078 22.22C13.5704 22.0929 13.4595 21.9399 13.3812 21.7699C13.303 21.5999 13.259 21.4161 13.2517 21.2291C13.2371 20.8514 13.3732 20.4834 13.6299 20.206L17.1221 16.4231H2.3249C1.94697 16.4231 1.58451 16.273 1.31728 16.0057C1.05004 15.7385 0.899902 15.376 0.899902 14.9981C0.899902 14.6202 1.05004 14.2577 1.31728 13.9905C1.58451 13.7232 1.94697 13.5731 2.3249 13.5731H17.1202L13.628 9.7921C13.501 9.65463 13.4023 9.49349 13.3375 9.31787C13.2728 9.14225 13.2433 8.9556 13.2507 8.76857C13.2581 8.58154 13.3023 8.39781 13.3807 8.22786C13.4592 8.05792 13.5703 7.90508 13.7078 7.7781Z"
              fill="#79869F"
            />
          </svg>
        </v-col>
      </v-row>

      <!-- <v-menu offsetY v-if="$store.getters.isAuthenticated">
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                icon
                class="mr-2"
                v-on="{ ...tooltip, ...menu }"
                @click="modal"
              >
                <v-icon size="26">mdi-video-plus-outline</v-icon>
              </v-btn>
            </template>
            <span>Post Your Property</span>
          </v-tooltip>
        </template>
      </v-menu>

      <v-tooltip v-if="$store.getters.isAuthenticated" bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" class="mr-2" router to="/notification">
            <v-icon size="22">mdi-bell-badge-outline</v-icon>
          </v-btn>
        </template>
        <span>Notification</span>
      </v-tooltip>

      <v-tooltip v-if="$store.getters.isAuthenticated" bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" class="mr-3" router to="/tagged">
            <v-icon size="22">mdi-clock-outline</v-icon>
          </v-btn>
        </template>
        <span>My Watchlist</span>
      </v-tooltip> -->

      <v-btn
        large
        tile
        outlined
        color="#79869f"
        class="font-weight-bold rounded hidden-sm-and-down"
        v-if="!$store.getters.isAuthenticated"
        router
        to="/signin"
      >
        <svg
          width="17"
          height="16"
          class="rounded cursor"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.75 15.5C6.55109 15.5 6.36032 15.421 6.21967 15.2803C6.07902 15.1397 6 14.9489 6 14.75C6 14.5511 6.07902 14.3603 6.21967 14.2197C6.36032 14.079 6.55109 14 6.75 14H13C13.5304 14 14.0391 13.7893 14.4142 13.4142C14.7893 13.0391 15 12.5304 15 12V4C15 3.46957 14.7893 2.96086 14.4142 2.58579C14.0391 2.21071 13.5304 2 13 2H6.75C6.55109 2 6.36032 1.92098 6.21967 1.78033C6.07902 1.63968 6 1.44891 6 1.25C6 1.05109 6.07902 0.860322 6.21967 0.71967C6.36032 0.579018 6.55109 0.5 6.75 0.5H13C13.9283 0.5 14.8185 0.868749 15.4749 1.52513C16.1313 2.1815 16.5 3.07174 16.5 4V12C16.5 12.9283 16.1313 13.8185 15.4749 14.4749C14.8185 15.1313 13.9283 15.5 13 15.5H6.75ZM6.741 4.199C6.81335 4.13214 6.89816 4.08019 6.9906 4.04612C7.08303 4.01205 7.18127 3.99652 7.2797 4.00042C7.37813 4.00432 7.47484 4.02757 7.56428 4.06885C7.65373 4.11013 7.73417 4.16863 7.801 4.241L10.801 7.491C10.929 7.62957 11.0002 7.81132 11.0002 8C11.0002 8.18868 10.929 8.37043 10.801 8.509L7.801 11.759C7.73409 11.8313 7.6536 11.8897 7.56412 11.9309C7.47464 11.9721 7.37792 11.9952 7.27949 11.999C7.18106 12.0028 7.08284 11.9872 6.99045 11.9531C6.89806 11.9189 6.81329 11.8669 6.741 11.8C6.66871 11.7331 6.6103 11.6526 6.56912 11.5631C6.52793 11.4736 6.50477 11.3769 6.50097 11.2785C6.49328 11.0797 6.56487 10.886 6.7 10.74L8.538 8.749H0.75C0.551088 8.749 0.360322 8.66998 0.21967 8.52933C0.0790177 8.38868 0 8.19791 0 7.999C0 7.80009 0.0790177 7.60932 0.21967 7.46867C0.360322 7.32802 0.551088 7.249 0.75 7.249H8.537L6.699 5.259C6.63214 5.18665 6.58019 5.10184 6.54612 5.0094C6.51205 4.91697 6.49652 4.81873 6.50042 4.7203C6.50432 4.62187 6.52757 4.52516 6.56885 4.43572C6.61013 4.34627 6.66863 4.26583 6.741 4.199Z"
            fill="#79869F"
          />
        </svg>
        &nbsp;&nbsp;
        <span class="text-gray">Login</span>
      </v-btn>

      <v-menu offset-y left v-else>
        <template v-slot:activator="{ on }">
          <v-btn
            small
            color="deep-orange"
            depressed
            fab
            v-on="on"
            class="white--text"
          >
            <template>
              <span class="headline">
                {{ currentUser.name.split("")[0].toUpperCase() }}
              </span>
            </template>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <template>
                  <v-avatar color="deep-orange">
                    <span class="white--text headline">
                      {{ currentUser.name.split("")[0].toUpperCase() }}
                    </span>
                  </v-avatar>
                </template>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-capitalize">
                  {{ currentUser.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ currentUser.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item router to="/myvideo">
              <v-list-item-icon>
                <v-icon>mdi-youtube</v-icon>
              </v-list-item-icon>
              <v-list-item-title>My Properties</v-list-item-title>
            </v-list-item>
            <v-list-item router @click="settingsMoal('Settings')">
              <v-list-item-icon>
                <v-icon>mdi-account-box-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Edit Profile</v-list-item-title>
            </v-list-item>
            <v-list-item router @click="signOut">
              <v-list-item-icon>
                <v-icon>mdi-login-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Sign out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <!-- <div>
      <v-row class="d-lg-none d-sm-flex d-md-none px-2 py-4">
        <v-col cols="12" sm="12" md="3" lg="3" class="mx-xs-auto">
          <v-select
            v-model="city"
            :items="cityItems"
            item-value="City"
            item-text="City"
            label="City"
            color="deep-orange deep-orange--text p-0"
            class="rounded-0"
            hide-details
            outlined
            dense
            clearable
            @input="searchMe"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="3" class="mx-xs-auto">
          <v-select
            v-model="city"
            :items="cityItems"
            item-value="City"
            item-text="City"
            label="City"
            color="deep-orange deep-orange--text p-0"
            class="rounded-0"
            hide-details
            outlined
            dense
            clearable
            @input="searchMe"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="3" class="mx-xs-auto">
          <v-combobox
            v-model="location"
            :items="locationItems"
            item-value="Locality"
            item-text="Locality"
            label="Locality"
            color="deep-orange deep-orange--text"
            hide-details
            outlined
            dense
            clearable
            @input="searchMe"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="3" class="mx-xs-auto">
          <v-text-field
            v-model="projectTitle"
            :items="titleItems"
            item-value="Locality"
            item-text="Locality"
            label="Project Title"
            append-icon="mdi-magnify"
            color="deep-orange"
            class="rounded"
            hide-details
            outlined
            dense
            clearable
            @input="searchMe"
          ></v-text-field>
        </v-col>
      </v-row>
    </div> -->
    <upload-video-modal
      :open-dialog="dialog"
      v-on:closeDialog="dialog = false"
      :video-limit="videoLimit"
    />
    <settings-modal
      v-if="isAuthenticated"
      :open-dialog="settingsDialog"
      v-on:closeDialog="settingsDialog = false"
    />
    <search-bar-modal
      :typeItems="typeItems"
      :cityItems="cityItems"
      :locationItems="locationItems"
      :titleItems="titleItems"
      :open-dialog="searchBar"
      @close="searchBar = false"
    />
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import HistoryService from "@/services/HistoryService";
import { Auth } from "aws-amplify";
import "bootstrap/dist/css/bootstrap.css";
import UploadVideoModal from "@/components/UploadVideoModal";
import SearchBarModal from "@/components/SearchBarModal";
import SettingsModal from "@/components/SettingsModal";
import VideoService from "@/services/VideoService";
import moment from "moment";
export default {
  data: () => ({
    search: "",

    loading: false,
    loaded: false,
    errored: false,
    cityItems: [],
    typeItems: ["All", "Buy", "Rent"],
    locationItems: [],
    filteredLocality: [],
    titleItems: [],
    links: [
      { text: "Home", link: "#" },
      { text: "About Us", link: "#" },
      { text: "Contact us", link: "#" },
      { text: "Terms & Conditions", link: "#" },
      { text: "Privacy Policy", link: "#" },
    ],
    searchText: "",
    videoLimit: false,
    dialog: false,
    searchBar: false,
    settingsDialog: false,
    projectTitle: null,
    city: null,
    type: "All",
    location: null,
  }),
  computed: {
    ...mapGetters(["currentUser", "getUrl", "isAuthenticated"]),
  },
  created() {
    this.getCity();
    this.getLocation();
  },
  methods: {
    async searchMe() {
      if (this.$route.path !== "/") {
        await this.$router.push({ name: "Welcome" });
      }
      this.$store.dispatch("addSearchData", {
        city: this.city,
        location: this.location,
        projectTitle: this.projectTitle,
        type: this.type,
      });
    },
    async search() {
      if (!this.searchText) return;
      // console.log(this.searchText == this.$route.query['search-query'])
      if (this.searchText == this.$route.query["search-query"]) return;
      // this.searchText = this.$route.query['search-query']
      const data = {
        type: "search",
        searchText: this.searchText,
      };

      if (this.isAuthenticated)
        await HistoryService.createHistory(data).catch((err) =>
          console.log(err)
        );

      await this.$router.push({
        name: "Search",
        query: { "search-query": this.searchText },
      });
    },
    async getCity() {
      const cityItemsData = await VideoService.getCity({ page: this.page })
        .catch(() => {
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });

      if (typeof cityItemsData === "undefined") return;
      this.cityItems = cityItemsData.data.Items;
    },
    async getLocation() {
      await VideoService.getLocation({ page: this.page })
        .then((r) => {
          this.locationItems = r.data.Items;
          this.titleItems = r.data.Items;
        })
        .catch(() => {
          this.errored = true;
        });
    },
    onSelectionChanged() {
      this.filteredLocality = this.locationItems.filter(
        (i) => i.city == this.city
      );
    },
    async signOut() {
      try {
        await Auth.signOut();
        await this.$store.dispatch("signOut");
        this.$router.push("/signin");
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },
    goToLogin() {
      this.$router.push("/signin");
    },
    goToNotification() {
      this.$router.push("/notification");
    },
    goToTagged() {
      this.$router.push("/tagged");
    },
    async modal() {
      await VideoService.getVideoCount()
        .then((res) => {
          // console.log("Get Video Count Called")
          // console.log("response ===>",res)
          if (res.data.statusCode == 200) {
            // console.log(res.data.statusCode==200);
            this.videoLimit = true;
          } else {
            this.videoLimit = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialog = true;
    },
    // opensearchBar() {
    //   this.searchBar = true;
    // },
    dateFormatter(date) {
      return moment(date).fromNow();
    },
    settingsMoal(title) {
      if (title !== "Settings") return;
      this.settingsDialog = true;
    },
    async reloadPage() {
      if (this.$route.path == "/") {
        console.log("Loading");
        await this.$store.dispatch("addSearchData", {
          city: "",
          location: "",
          projectTitle: "",
          type: "All",
        });
      }
    },
  },
  components: {
    UploadVideoModal,
    SearchBarModal,
    SettingsModal,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!to.query["search-query"] === "") return;
      vm.searchText = to.query["search-query"];
      // vm.getSearchResults(to.query['search-query'])
    });
  },
};
</script>

<style lang="scss">
.v-list-item__avatar {
  justify-content: center !important;
}
.v-select__slot:hover {
  cursor: pointer;
}

.v-text-field--outlined,
.v-text-field--solo,
.v-btn--tile {
  border-radius: 2px !important;
}

#showBtn {
  .v-btn__content {
    justify-content: flex-start;

    i {
      margin-right: 30px;
    }
  }
}

#navbar {
  .active-item {
    .v-list-item__icon {
      color: red !important;
    }
  }

  .v-navigation-drawer__border {
    width: 0 !important;
  }

  .vuebar-element {
    height: 250px;
    width: 100%;
    max-width: 500px;
    background: white;
  }

  .vb > .vb-dragger {
    z-index: 5;
    width: 10px;
    right: 0;
  }

  .vb > .vb-dragger > .vb-dragger-styler {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotate3d(0, 0, 0, 0);
    transform: rotate3d(0, 0, 0, 0);
    -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
      height 100ms ease-out;
    transition: background-color 100ms ease-out, margin 100ms ease-out,
      height 100ms ease-out;

    margin: 5px 5px 5px 0;
    border-radius: 20px;
    height: calc(100% - 10px);
    display: block;
  }

  .v-navigation-drawer__content:hover .vb > .vb-dragger > .vb-dragger-styler {
    width: 10px;
    background-color: white;
  }

  .vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.3);
    background-color: rgba(255, 255, 255, 0.3);
  }

  .vb > .vb-dragger:hover > .vb-dragger-styler {
    margin: 0px;
    width: 10px;
  }

  .vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.5);
    margin: 0px;
    height: 100%;
  }

  .vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.5);
  }
}

.select {
  max-width: 30px;
  max-height: 30px;
}

// .c-height {}

#navbar
  .v-text-field--filled.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  min-height: 35px !important;
}

#navbar .v-input__control {
  display: flex;
  flex-direction: column;
  height: 35px !important;
}

.text-gray {
  color: #79869f;
}

.v-btn--fab.v-size--small {
  height: 30px !important;
  width: 30px !important;
}

.v-application .headline {
  font-size: 1rem !important;
}

.v-dialog__content {
  align-items: flex-start;
  justify-content: center;
}

.v-input__slot:hover {
  cursor: pointer;
}

.my-col-1 {
  border-style: solid;
  border-width: 1px 0px 1px 1px; /* Replace with your desired border styles */
  border-color: rgba(236, 236, 236, 1);
  border-radius: 7px 0px 0px 7px;
  padding: 0.4%;
}
.my-col-2 {
  border-style: solid;
  border-width: 1px 0px 1px 0px; /* Replace with your desired border styles */
  border-color: rgba(236, 236, 236, 1);
  padding: 0.4%;
}
.my-col-3 {
  border-style: solid;
  border-width: 1px 1px 1px 0px; /* Replace with your desired border styles */
  border-color: rgba(236, 236, 236, 1);
  border-radius: 0px 7px 7px 0px;
  padding: 0.4%;
}

.cursor {
  cursor: pointer;
}
.middle-line {
  height: 65%;
  align-self: center;
  width: 1px;
  background-color: rgba(236, 236, 236, 1);
}
</style>
