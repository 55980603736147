<template>
  <v-dialog v-model="searchBar" content-class="elevation-0">
    <v-row no-gutters>
      <v-col
        cols="12"
        xl="4"
        md="3"
        sm="12"
        xs="12"
        class="mobile-view-bottom-margin"
      >
        <v-select
          flat
          solo
          v-model="type"
          :items="typeItems"
          item-value="Type"
          item-text="Type"
          label="Type"
          color="deep-orange deep-orange--text p-0"
          class="rounded"
          hide-details
          dense
          clearable
        ></v-select>
        <!-- @change="onSelectionChanged" -->
        <!-- outlined -->
      </v-col>
      <v-col
        cols="12"
        xl="4"
        md="3"
        sm="12"
        xs="12"
        class="mobile-view-bottom-margin"
      >
        <v-select
          flat
          solo
          v-model="city"
          :items="cityItems"
          item-value="city"
          item-text="city"
          label="City"
          color="deep-orange deep-orange--text p-0"
          class="rounded"
          @change="onSelectionChanged"
          hide-details
          dense
          clearable
        ></v-select>
        <!-- outlined -->
      </v-col>
      <v-col
        cols="12"
        xl="4"
        md="3"
        sm="12"
        xs="12"
        class="mobile-view-bottom-margin"
      >
        <v-combobox
          flat
          solo
          v-model="location"
          :items="filteredLocality"
          item-value="location"
          item-text="location"
          label="Locality"
          color="deep-orange deep-orange--text"
          hide-details
          dense
          clearable
        ></v-combobox>
        <!-- outlined -->
      </v-col>
      <v-col
        cols="12"
        xl="4"
        md="6"
        sm="12"
        xs="12"
        class="mobile-view-bottom-margin"
      >
        <!-- append-icon="mdi-magnify" -->
        <v-text-field
          flat
          solo
          :value="projectTitle"
          :items="titleItems"
          item-value="Locality"
          item-text="Locality"
          label="Project Title"
          color="#f3a288"
          class="rounded"
          hide-details
          dense
          clearable
        >
          <template v-slot:append>
            <!-- search button svg -->
            <svg
              width="37"
              height="35"
              viewBox="0 0 37 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mobile-view-remove"
            >
              <rect width="37" height="34.2927" rx="3.22729" fill="#FBA388" />
              <path
                d="M27.6 26L21.3 19.7C20.8 20.1 20.225 20.4167 19.575 20.65C18.925 20.8833 18.2333 21 17.5 21C15.6833 21 14.146 20.371 12.888 19.113C11.6293 17.8543 11 16.3167 11 14.5C11 12.6833 11.6293 11.1457 12.888 9.887C14.146 8.629 15.6833 8 17.5 8C19.3167 8 20.8543 8.629 22.113 9.887C23.371 11.1457 24 12.6833 24 14.5C24 15.2333 23.8833 15.925 23.65 16.575C23.4167 17.225 23.1 17.8 22.7 18.3L29 24.6L27.6 26ZM17.5 19C18.75 19 19.8127 18.5627 20.688 17.688C21.5627 16.8127 22 15.75 22 14.5C22 13.25 21.5627 12.1873 20.688 11.312C19.8127 10.4373 18.75 10 17.5 10C16.25 10 15.1873 10.4373 14.312 11.312C13.4373 12.1873 13 13.25 13 14.5C13 15.75 13.4373 16.8127 14.312 17.688C15.1873 18.5627 16.25 19 17.5 19Z"
                fill="white"
              />
            </svg>
          </template>
        </v-text-field>
        <!-- outlined -->
      </v-col>
      <v-col cols="12" xl="4" md="4" sm="12" xs="12" class="hidden-sm-and-up">
        <v-btn
          variant="tonal"
          color="#FBA388"
          class="mr-5 mb-3 white--text"
          style="width: 100%"
          @click="searchMe"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30.4333 33L18.8833 21.45C17.9667 22.1833 16.9125 22.7639 15.7208 23.1917C14.5292 23.6194 13.2611 23.8333 11.9167 23.8333C8.58611 23.8333 5.76767 22.6802 3.46133 20.3738C1.15378 18.0663 0 15.2472 0 11.9167C0 8.58611 1.15378 5.76706 3.46133 3.4595C5.76767 1.15317 8.58611 0 11.9167 0C15.2472 0 18.0663 1.15317 20.3738 3.4595C22.6802 5.76706 23.8333 8.58611 23.8333 11.9167C23.8333 13.2611 23.6194 14.5292 23.1917 15.7208C22.7639 16.9125 22.1833 17.9667 21.45 18.8833L33 30.4333L30.4333 33ZM11.9167 20.1667C14.2083 20.1667 16.1566 19.3649 17.7613 17.7613C19.3649 16.1566 20.1667 14.2083 20.1667 11.9167C20.1667 9.625 19.3649 7.67678 17.7613 6.072C16.1566 4.46844 14.2083 3.66667 11.9167 3.66667C9.625 3.66667 7.67678 4.46844 6.072 6.072C4.46844 7.67678 3.66667 9.625 3.66667 11.9167C3.66667 14.2083 4.46844 16.1566 6.072 17.7613C7.67678 19.3649 9.625 20.1667 11.9167 20.1667Z"
              fill="white"
            />
          </svg>

          <span class="text-capitalize" style="font-size: 80%; font-weight: 300"
            >Search</span
          >
        </v-btn>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
export default {
  name: "SearchBarModal",
  props: [
    "openDialog",
    "cityItems",
    "locationItems",
    "titleItems",
    "typeItems",
  ],
  data: () => ({
    filteredLocality: [],
    type: "All",
    city: null,
    location: null,
    projectTitle: null,
  }),
  computed: {
    searchBar: {
      get() {
        return this.openDialog;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    async searchMe() {
      // if (this.$route.path !== "/") {
      //   await this.$router.push({ name: "Welcome" });
      // }
      await this.$store.dispatch("addSearchData", {
        type: this.type,
        city: this.city,
        location: this.location,
        projectTitle: this.projectTitle,
      });

      this.$emit("close");
    },
    onSelectionChanged() {
      this.filteredLocality = this.locationItems.filter(
        (i) => i.city == this.city
      );
    },
  },
};
</script>
<style>
@media (max-width: 480px) {
  .mobile-view-bottom-margin {
    margin-bottom: 10px;
    height: 50%;
  }
  .mobile-view-remove {
    display: none !important;
  }
  .v-dialog {
    overflow: hidden;
  }
}
</style>
