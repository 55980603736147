import Api from '@/services/Api';
import {Auth} from 'aws-amplify';
//import { API } from 'aws-amplify';

export default {
    updateUserDetails(data) {
        return Api().put('auth/updatedetails', data)
    },
    uploadUserAvatar(data) {
        return Api().put('auth/avatar', data)
    },
    updatePassword(data) {
        return Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, data.currentPassword, data.newPassword);
            });
    },
    me(token) {
        return Api().post('auth/me', {
            headers: {Authorization: `Bearer ${token}`}
        })
    },
    async UpdateUser(data) {
        const user = await Auth.currentAuthenticatedUser();
        return Api().post(
            "https://mpcmml9y6f.execute-api.ap-south-1.amazonaws.com/prod/videoplatform/user",
            data,
            {
                headers: {
                    Authorization: user.signInUserSession.idToken.jwtToken
                },
            }
        );
    },
}
