<template>
  <v-footer dark padless>
    <v-card class="flex" flat tile>
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-4 white--text text-center">
            Copyright © {{ new Date().getFullYear() }} <strong>EndWait</strong>
          </div>
          <div class="col-sm-12 col-md-4 white--text text-center">
            <a
              style="text-decoration: none; color: #ffffff"
              href="mailto:'contact@endwait.com'"
              >contact@endwait.com</a
            >
          </div>
          <div class="col-sm-12 col-md-4 white--text text-center">
            <a
              style="text-decoration: none; color: #ffffff"
              href="https://s3.ap-south-1.amazonaws.com/endwait.com/privacy+policy.html"
            >
              Privacy Policy</a
            >
          </div>
        </div>
      </div>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    links: [
      "Home",
      "About Us",
      "Contact Us",
      "Privacy Policy",
      "Terms & Conditions",
    ],
  }),
};
</script>

<style scoped>
@media (max-width: 480px) {
  .text-center {
    margin-bottom: -10px;
  }
}
</style>
